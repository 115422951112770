@import '../../scss/imports';

.sidebar {
  background: $body-bg-color;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: rem(16px);
  width: rem(236px);
  z-index: 1;

  &.collapsed {
    width: rem(80px);
  }

  @include max-screen(800px) {
    display: none;
  }
}

.side-menu,
.side-menu-small {
  list-style-type: none;

  .md-list-item {
    position: relative;

    .md-list-tile {
      border-radius: 0 100em 100em 0;
      height: rem(42px);
    }

    .md-list-tile--active {
      background: rgba(#1BAFF5, 0.2);
      border-radius: 0 100em 100em 0;
      color: $primary-color;
    }

    &.active {
      background: rgba(#1BAFF5, 0.2);
      border-radius: 0 100em 100em 0;
      color: $primary-color;
      font-family: $font-demi;

      .md-icon,
      .md-text,
      svg {
        color: $primary-color;
      }
    }
  }
}
