@import '../../scss/imports';

.event-tile {
  background-color: #F4F6F8;
  border-radius: 2px;
  color: $secondary-text-color;
  cursor: pointer;
  margin-bottom: rem(8px);
  padding: rem(10px 10px 6px 18px);
  position: relative;

  .status-indicator {
    background-color: #EEEEEE;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: rem(8px);
  }

  .event-tile-name {
    font-family: $font-demi;
  }

  .event-tile-text,
  .event-tile-name {
    font-size: rem(12px);
    margin-bottom: rem(4px);
  }
}

.event-details-dialog {
  .event-details {
    padding: rem(0 24px);
  }

  ul {
    list-style: none;

    li {
      margin-bottom: rem(24px);
    }
  }

  .close-button {
    position: absolute;
    right: rem(16px);
    top: rem(16px);
  }

  .event-image-container {
    height: rem(200px);
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .details-list {
    padding: 0;

    li {
      display: inline-block;
      padding-left: rem(48px);
      position: relative;
      width: 100%;

      .details-icon {
        left: 0;
        position: absolute;
        top: 0;

        .md-icon.material-icons {
          font-size: 24px;
        }
      }
    }

    &.integrations-list {
      margin-top: rem(8px);

      li {
        display: inline-block;
        height: rem(24px);
        line-height: rem(24px);
        margin-bottom: rem(4px);
        padding-left: rem(32px);
      }

      .detail-name {
        font-family: $font-regular;
      }

      .integration-name {
        text-transform: capitalize;
      }
    }
  }

  .event-name {
    color: $secondary-text-color;
    font-family: $font-demi;
    font-size: rem(24px);
  }

  .detail-name {
    color: $secondary-text-color;
    font-family: $font-demi;
    font-size: rem(14px);
  }

  .detail-description {
    color: $secondary-text-color;
    font-size: rem(12px);
    font-family: $font-regular;
    margin-top: rem(3px);
  }

  .status-icon {
    background-color: #EEE;
    border-radius: 50%;
    display: inline-block;
    height: rem(24px);
    width: rem(24px);
  }

  .detail-tag {
    background-color: #e5e5e5;
    border-radius: 2px;
    display: inline-block;
    margin-top: rem(8px);
    padding: rem(7px 16px);
  }
}
