@import '../../scss/imports';

#layout {
  display: flex;

  h1 {
    color: #000;
  }

  .content-container {
    flex-grow: 1;
    margin-top: rem(24px);
    padding-right: rem(20px);
    position: relative;
  }

  .separator {
    margin: 0;
  }
}
