@import '../../scss/imports';

#agenda {
  .date-title {
    color: $secondary-text-color;
    font-size: rem(32px);
    margin: rem(24px 0);
  }

  .to-top-button {
    bottom: 20px;
    position: fixed;
    right: 20px;

    .material-icons.md-icon {
      font-size: rem(32px);
    }
  }
}
