@import '../../scss/imports';

.top-action-bar {
  margin-bottom: rem(24px);
  padding: 0;
  z-index: 1;

  .md-icon-text {
    padding-left: rem(8px);
  }

  .date-controls {
    h4 {
      display: inline-block;
      font-size: rem(30px);
      margin: 0;
    }

    .date-nav,
    h4 {
      color: $secondary-text-color;
    }
  }

  .action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .search-input-container {
    margin-right: rem(8px);
    max-width: rem(190px);
  }
}
