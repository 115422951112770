@import '../../scss/imports';

#week {
  .calender {
    margin-top: rem(32px);
    min-height: rem(512px);
  }

  .calender-head {
    text-align: center;
    padding-bottom: rem(32px);

    .day,
    .date {
      color: $secondary-text-color;
    }

    .day {
      font: rem(14px) $font-demi;
      margin: 0 0 rem(4px) 0;
    }

    .date {
      display: inline-block;
      font: rem(22px) $font-demi;
      line-height: 32px;
      height: 32px;
      margin: 0;
      width: 32px;
    }

    &.date-today {
      .day,
      .date {
        color: #1BAFF5;
      }

      .date {
        background-color: #1BAFF5;
        border-radius: 50%;
        color: #FFF;
      }
    }

    &.date-before {
      .day,
      .date {
        color: rgba(#000, .3);
      }
    }

    &.date-after {
      .day,
      .date {
        color: $secondary-text-color;
      }
    }
  }

  .calender-event-container {
    border-left: 1px solid rgba(#000, .1);
    min-height: 100%;
    padding: rem(16px 4px);
  }

  .day-container {
    align-self: stretch;
  }
}
